import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import { TextField, Button, Typography, Paper } from "@mui/material";
import { NumericFormat } from "react-number-format";

function InvestmentCalculator() {
  const [initialInvestment, setInitialInvestment] = useState("");
  const [annualReturn, setAnnualReturn] = useState("");
  const [years, setYears] = useState("");
  const [result, setResult] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Sayfa yüklendiğinde kontrol et
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateReturn = () => {
    const investment = parseFloat(initialInvestment); // TL değerini al
    const returnRate = parseFloat(annualReturn) / 100; // Yıllık getiri oranını yüzdelik olarak al
    const time = parseInt(years); // Yıl sayısını al

    console.log("Başlangıç Yatırımı:", investment);
    console.log("Yıllık Getiri Oranı:", returnRate);
    console.log("Yıl Sayısı:", time);

    if (isNaN(investment) || isNaN(returnRate) || isNaN(time)) {
      alert("Lütfen tüm alanları doldurun!");
      return;
    }

    // Gelecekteki değeri ve kârı hesapla
    const futureValue = investment * Math.pow(1 + returnRate, time);
    const profit = futureValue - investment;

    console.log("Gelecekteki Değer:", futureValue);
    console.log("Kâr:", profit);

    // TL biçimlendirmesi ile sonuçları ayarlayın
    const formattedFutureValue = futureValue.toLocaleString("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const formattedProfit = profit.toLocaleString("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setResult(
      `Yatırımın Gelecekteki Değeri: ${formattedFutureValue}\nKâr: ${formattedProfit}`
    );
  };

  const handleValueChange = (values) => {
    const { floatValue } = values;
    if (floatValue !== undefined) {
      setInitialInvestment(floatValue); // Doğrudan floatValue kullan
    }
  };

  return (
    <Draggable disabled={isMobile}>
      <Paper
        elevation={3}
        style={{
          padding: "20px",
          maxWidth: "400px",
          margin: "auto",
          cursor: "move",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Yatırım Getirisi Hesaplayıcı
        </Typography>
        <NumericFormat
          label="Başlangıç Yatırımı (TL)"
          value={initialInvestment}
          onValueChange={handleValueChange}
          customInput={TextField}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          isNumericString={true}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          fullWidth
          label="Yıllık Getiri Oranı (%)"
          variant="outlined"
          value={annualReturn}
          onChange={(e) => setAnnualReturn(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <TextField
          fullWidth
          label="Yıl Sayısı"
          variant="outlined"
          value={years}
          onChange={(e) => setYears(e.target.value)}
          style={{ marginBottom: "20px" }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={calculateReturn}
          fullWidth
        >
          Hesapla
        </Button>
        {result && (
          <Typography variant="h6" align="center" style={{ marginTop: "20px" }}>
            {result}
          </Typography>
        )}
      </Paper>
    </Draggable>
  );
}

export default InvestmentCalculator;
