let envConfig={}

const devConfig={
    apiUrl:"http://localhost:5003",
    appUrl:"http://localhost:3000"
}

const prodConfig = {
    apiUrl: "https://shsteknoloji.com/api/",
    appUrl: "https://shsteknoloji.com"
};


if (process.env.REACT_APP_ENV==="development") {
    envConfig=devConfig;
}
else if(process.env.REACT_APP_ENV==="production"){
  envConfig=prodConfig
}

export default envConfig;