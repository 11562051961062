import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable"; // Draggable kütüphanesini içe aktar
import { TextField, Button, Typography, Box, Paper } from "@mui/material";
import { NumericFormat } from "react-number-format";
const MiniCalculator = () => {
  const [amount, setAmount] = useState("");
  const [conversionResult, setConversionResult] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({ usdToTry: 0, eurToTry: 0 });
  const widgetLoadedRef = useRef(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // 768px altındaki ekranlar mobil kabul edilir
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Sayfa yüklendiğinde kontrol et
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const loadTradingViewWidget = () => {
      if (widgetLoadedRef.current) return;

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          { description: "DOLAR/TL", proName: "BYBIT:USDTTRY" },
          { description: "EURO/TL", proName: "WHSELFINVEST:EURTRY" },
          { description: "BTC", proName: "BITSTAMP:BTCUSD" },
          { description: "ETH", proName: "BINANCE:ETHUSD" },
          { description: "Gram Altın", proName: "FX_IDC:XAUTRYG" }
        ],
        showSymbolLogo: true,
        isTransparent: false,
        displayMode: "adaptive",
        colorTheme: "light",
        locale: "tr"
      });
      document.querySelector(".tradingview-widget-container__widget").appendChild(script);
      widgetLoadedRef.current = true;
    };

    loadTradingViewWidget();
  }, []);

  const convertCurrency = (currency) => {
    let result;
    let convertedCurrency;

    // amount değerini sayıya çevir
    const numericAmount = parseFloat(amount.replace('.', '').replace(',', '.'));

    if (currency === "USD") {
      result = (numericAmount * exchangeRates.usdToTry).toFixed(2);
      convertedCurrency = "TRY";
    } else if (currency === "EUR") {
      result = (numericAmount * exchangeRates.eurToTry).toFixed(2);
      convertedCurrency = "TRY";
    } else if (currency === "TRY_TO_USD") {
      result = (numericAmount / exchangeRates.usdToTry).toFixed(2);
      convertedCurrency = "USD";
    } else if (currency === "TRY_TO_EUR") {
      result = (numericAmount / exchangeRates.eurToTry).toFixed(2);
      convertedCurrency = "EUR";
    }

    setConversionResult(`${result} ${convertedCurrency}`);
  };


  const fetchExchangeRates = async () => {
    try {
      const response = await fetch("https://api.exchangerate-api.com/v4/latest/USD");
      const data = await response.json();

      const rates = {
        usdToTry: data.rates.TRY, // USD'den TRY'ye oran
        eurToTry: data.rates.TRY / data.rates.EUR, // EUR'dan TRY'ye oran
      };

      setExchangeRates(rates);
    } catch (error) {
      console.error("Döviz kurları alınırken hata:", error);
    }
  };

  useEffect(() => {
    fetchExchangeRates();
  }, []);

  const handleValueChange = (values) => {
    const { formattedValue, value } = values;
    setAmount(formattedValue);
  };
  return (
    <Draggable disabled={isMobile}>
      <Paper elevation={3} style={{ padding: "20px", maxWidth: "400px", margin: "auto", cursor: "move" }}>
        <Typography variant="h6" gutterBottom>Döviz Çevirici</Typography>
        {/* <TextField
          label="Miktar"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          variant="outlined"
          fullWidth
          margin="normal"
          type='number'
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
          }}
        /> */}
        <NumericFormat
          label="Miktar"
          value={amount}
          onValueChange={handleValueChange}
          customInput={TextField}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          isNumericString={true}
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <Button
          onClick={() => convertCurrency("USD")}
          variant="contained"
          color="success"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          USD'yi TRY'ye Çevir
        </Button>
        <Button
          onClick={() => convertCurrency("EUR")}
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          EUR'yu TRY'ye Çevir
        </Button>
        <Button
          onClick={() => convertCurrency("TRY_TO_USD")}
          variant="contained"
          color="secondary"
          fullWidth
          style={{ marginBottom: "10px" }}
        >
          TRY'yi USD'ye Çevir
        </Button>
        <Button
          onClick={() => convertCurrency("TRY_TO_EUR")}
          variant="contained"
          color="warning"
          fullWidth
        >
          TRY'yi EUR'ya Çevir
        </Button>
        {conversionResult && (
          <Box mt={2} textAlign="center">
            <strong>Çevrilen Miktar: </strong>
            <NumericFormat
              value={parseFloat(conversionResult.split(' ')[0])} // Sadece sayı kısmını al
              displayType="text"
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={` ${conversionResult.split(' ')[1]}`} // Para birimi kısmını ekle
            />
          </Box>
        )}
        <div className="tradingview-widget-container" style={{ marginTop: "20px" }}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            {/* Burada telif hakkı bilgisi ekleyebilirsiniz */}
          </div>
        </div>
      </Paper>
    </Draggable>
  );
};

export default MiniCalculator;
