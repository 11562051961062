import React, { useEffect, useState } from 'react';
import axios from "axios";
import config from "../config";
import "../customCss/footer.css";
 

function VisitorCount() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        // Ziyaretçi sayısını al ve güncelle
        const fetchVisitorCount = async () => {
            try {
                console.log('config.apiUrl :>> ', config.apiUrl);
                const response = await axios.get(`${config.apiUrl}/visitor`); // GET isteği
                console.log('response :>> ', response);
                setCount(response.data.count); // Ziyaretçi sayısını güncelle
            } catch (error) {
                console.error('Hata:', error);
                if (error.response) {
                    console.error('Response:', error.response.data);
                }
            }
        };

        fetchVisitorCount();
    }, []);

    return (
        <footer className="footer">
            <div className="visitor-info">
                <i className="fas fa-user visitor-icon"></i>
                <span className="visitor-count">{count}</span>
            </div>
        </footer>
    );
}

export default VisitorCount;
