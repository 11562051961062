import React from 'react';

const BuyMeACoffeeButton = () => {
  const handleClick = () => {
    window.open("https://www.buymeacoffee.com/serhathalil", "_blank"); // Butona tıklandığında açılacak link
  };

  return (
    <div className="flex justify-center">
      <img 
        src={`${process.env.PUBLIC_URL}/bmc-button.png`} // Public dizinindeki resmi kullan
        alt="Buy me a coffee" 
        className="cursor-pointer hover:opacity-75 transition-opacity h-12 w-auto" // Tailwind sınıfları ile boyutlandırma
        onClick={handleClick} // Tıklama olayını dinle
      />
    </div>
  );
};

export default BuyMeACoffeeButton;
