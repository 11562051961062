import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Blog from "./components/Blog";
import AdminPanel from "./components/AdminPanel";
import NotFound from './components/NotFound'; // 404 sayfası bileşeni
 
// import Portfolio from "./components/Portfolio";
// import Contact from "./components/Contact";
// import "./App.css";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} /> 
        <Route path="/adminPanel" element={<AdminPanel />} /> 
 
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
