import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { NumericFormat } from "react-number-format";
import Draggable from "react-draggable";
import FinancialFreedomInfoCard from "./FinancialFreedomInfoCard";

function FinancialFreedomCalculator() {
  const [monthlyExpenses, setMonthlyExpenses] = useState("");
  const [currentSavings, setCurrentSavings] = useState("");
  const [monthlyContribution, setMonthlyContribution] = useState("");
  const [annualReturnRate, setAnnualReturnRate] = useState("");
  const [results, setResults] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Sayfa yüklendiğinde kontrol et
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCalculate = () => {
    const monthlyReturnRate = annualReturnRate / 100 / 12; // Aylık getiri oranı
    const yearlyExpenses = monthlyExpenses * 12; // Yıllık harcama

    // Gerekli birikim hesaplama (Yıllık harcama x 25)
    const requiredSavings = yearlyExpenses * 25;

    // Toplam ay hesaplama
    let totalMonths = 0;
    let totalSavings = currentSavings;

    // Mevcut birikimle, aylık katkılarla birikimi hesaplama
    while (totalSavings < requiredSavings) {
        totalSavings += monthlyContribution; // Aylık katkıyı ekle
        totalSavings *= (1 + monthlyReturnRate); // Aylık getiri ekle
        totalMonths++;
    }

    const yearsToFreedom = (totalMonths / 12).toFixed(2); // Yıllara çevirme

    setResults({
      requiredSavings: requiredSavings > 0 ? requiredSavings.toFixed(2) : "0", // Negatif birikim olamaz
      yearsToFreedom: yearsToFreedom,
    });
};

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
        <Grid item xs={12}>
          <Draggable disabled={isMobile}>
            <Box
              sx={{
                mx: "auto",
                p: 3,
                boxShadow: 3,
                borderRadius: 2,
                background: "white",
                cursor: "move",
              }}
            >
              <Typography variant="h5" style={{ color: "black" }} gutterBottom>
                Finansal Özgürlük Hesaplayıcı
              </Typography>

              {/* Finansal Bilgiler Kartı */}
              <FinancialFreedomInfoCard />

              <Box sx={{ mb: 2 }}>
                <NumericFormat
                  customInput={TextField}
                  label="Aylık Yaşam Giderleri (₺)"
                  value={monthlyExpenses}
                  onValueChange={(values) => setMonthlyExpenses(Number(values.value))}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="₺ "
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <NumericFormat
                  customInput={TextField}
                  label="Mevcut Birikim (₺)"
                  value={currentSavings}
                  onValueChange={(values) => setCurrentSavings(Number(values.value))}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="₺ "
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <NumericFormat
                  customInput={TextField}
                  label="Aylık Yatırım Katkısı (₺)"
                  value={monthlyContribution}
                  onValueChange={(values) => setMonthlyContribution(Number(values.value))}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix="₺ "
                  fullWidth
                />
              </Box>

              <Box sx={{ mb: 2 }}>
                <NumericFormat
                  customInput={TextField}
                  label="Yıllık Getiri Oranı (%)"
                  value={annualReturnRate}
                  onValueChange={(values) => setAnnualReturnRate(Number(values.value))}
                  suffix="%"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  fullWidth
                />
              </Box>

              <Button variant="contained" color="primary" fullWidth onClick={handleCalculate}>
                Hesapla
              </Button>

              {results.requiredSavings && (
                <Box sx={{ mt: 3, color: "black" }}>
                  <Typography>
                    Finansal Özgürlük İçin Gerekli Toplam Birikim:{" "}
                    <strong>
                      <NumericFormat
                        value={results.requiredSavings}
                        displayType="text"
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix="₺ "
                      />
                    </strong>
                  </Typography>
                  <Typography>
                    Hedefe Ulaşma Süresi: <strong>{results.yearsToFreedom} Yıl</strong>
                  </Typography>
                </Box>
              )}
            </Box>
          </Draggable>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FinancialFreedomCalculator;
