import React, { useState } from "react";
import { Typography, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import "../customCss/financialFreedom.css"; // CSS dosyasını içe aktar

function FinancialFreedomInfoCard() {
    const [open, setOpen] = useState(false); // Popup'ın açık/kapalı durumunu yönetmek için

    const handleClickOpen = () => {
        setOpen(true); // Popup'ı aç
    };

    const handleClose = () => {
        setOpen(false); // Popup'ı kapat
    };

    return (
        <>

            <Button
                variant="contained"
                color="success"
                onClick={handleClickOpen} // Popup'ı aç

            >
                Finansal Özgürlük Nedir?
            </Button>

            {/* Diğer içerikler... */}



            {/* Popup Modal */}
             <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle  style={{color:"#e3ca38", "font-weight":"bold"}}>Finansal Özgürlük Nedir?</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            Finansal özgürlük, pasif gelirlerinizin yaşam giderlerinizi karşılayacak düzeye ulaşması
            durumudur. Bu, artık çalışmak zorunda olmadan istediğiniz hayatı yaşamanıza olanak sağlar.
          </Typography>

          <Typography   style={{color:"#e3ca38", "font-weight":"bold"}} variant="h6" gutterBottom>
            Aylık Yatırım Katkısı Nedir?
          </Typography>
          <Typography variant="body2" paragraph>
            Bu, her ay birikimlerinize düzenli olarak eklemek istediğiniz tutardır. Örneğin:
            <ul>
              <li>Her ay maaşınızdan 20.000 TL'yi yatırım yapmaya ayırıyorsanız, bu "Aylık Yatırım Katkısı"dır.</li>
            </ul>
            Bu tutar, mevcut birikimleriniz dışında düzenli olarak tasarruf etmeyi planladığınız miktarı
            ifade eder.
          </Typography>

          <Typography   style={{color:"#e3ca38", "font-weight":"bold"}} variant="h6" gutterBottom>
            Yıllık Getiri Oranı Nedir?
          </Typography>
          <Typography variant="body2" paragraph>
            Bu, yatırım araçlarından (örneğin, borsa, altın, fonlar) yıllık olarak beklediğiniz yüzdelik getiridir.
            Örneğin:
            <ul>
              <li>Eğer yıllık getiri oranı %10 ise, 100.000 TL'lik bir yatırım 1 yıl sonunda yaklaşık 110.000 TL olur.</li>
              <li>Güvenli bir yatırım aracı (örneğin, mevduat faizi) seçerseniz, bu oran genelde %5-%8 arası olur.</li>
            </ul>
          </Typography>

          <Typography   style={{color:"#e3ca38", "font-weight":"bold"}} variant="h6" gutterBottom>
            Finansal Özgürlük İçin Gerekli Miktar
          </Typography>
          <Typography variant="body2">
            Hesaplama sonucunda finansal özgürlüğünüzü elde etmek için gereken toplam birikim tutarı ve
            hedefe ulaşma süreniz gösterilecektir. Unutmayın, düzenli yatırım ve disiplin bu hedefe
            ulaşmada en önemli faktörlerdir.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
        </>
    );
}

export default FinancialFreedomInfoCard;
