import React from "react";
import BuyMeACoffeeButton from './BuyMeCoffee';

function About() {
    return (
        <section className="flex flex-col justify-center items-center h-screen p-6" style={{ background: 'linear-gradient(135deg, #8d8556, #3c3a32)' }}>

            <div className="home-content flex-grow text-center max-w-2xl">
                {/* Profil Fotoğrafı */}
                {/* <div className="profile-photo mb-4">
                    <img
                        src="/path/to/your-photo.jpg"
                        alt="Serhat Halil Silsüpür"
                        className="rounded-full w-32 h-32"
                    />
                </div> */}
                <h1 className="text-3xl font-bold mb-2 text-white">Serhat Halil Silsüpür</h1>
                <h3 className="text-xl font-semibold mb-4 text-white">Yazılım Geliştirici</h3>
                <p className="text-gray-300 mb-4"> {/* Metin rengi açık gri yapıldı */}
                    Yenilikçi çözümler üreten, problem çözmeyi seven bir yazılım
                    geliştiriciyim. 2014 yılından beri Türkiye'nin önde gelen bilişim firmaları ve kamu kurumlarında yazılım geliştirme,
                    uygulama geliştirme danışmanlığı, eğitmenlik gibi görevlerde bulundum.
                    Yeni teknolojileri öğrenmek ve bunları projelerde kullanmak benim için bir tutku.
                </p>

                <div className="social-media-links flex justify-center space-x-6 mb-4">
                    <a
                        href="https://www.linkedin.com/in/serhat-halil-sils%C3%BCp%C3%BCr-294a10a9/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-blue-400 transition-colors" // Beyaz yapıldı
                    >
                        <i className="fab fa-linkedin fa-2x"></i>
                    </a>
                    <a
                        href="https://www.youtube.com/@SHS_motors"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-red-400 transition-colors" // Beyaz yapıldı
                    >
                        <i className="fab fa-youtube fa-2x"></i>
                    </a>
                </div>
            </div>

            {/* "Buy Me a Coffee" Butonu */}
            <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2">
                <BuyMeACoffeeButton />
            </div>
        </section>
    );
}

export default About;
