import React, { useState } from "react";
import config from "../config";
import axios from "axios";
import Swal from "sweetalert2";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basit matematik sorusu oluşturma
    const number1 = Math.floor(Math.random() * 10);
    const number2 = Math.floor(Math.random() * 10);
    const correctAnswer = number1 + number2;

    const { value: userAnswer } = await Swal.fire({
      title: `CAPTCHA: ${number1} + ${number2} = ?`,
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Gönder',
      cancelButtonText: 'İptal',
      preConfirm: (inputValue) => {
        if (parseInt(inputValue) !== correctAnswer) {
          Swal.showValidationMessage('Yanlış cevap, lütfen tekrar deneyin.');
          return false;
        }
      }
    });

    if (userAnswer) {
      // Doğru cevap verildiyse form gönderimi
      try {
        const response = await axios.post(`${config.apiUrl}/contact`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        Swal.fire('Başarılı!', response.data.message, 'success'); // Başarılı mesajı
        setFormData({ name: "", email: "", message: "" }); // Formu sıfırla
      } catch (error) {
        console.log("Bir hata oluştu:", error);
        Swal.fire('Hata!', 'Bir hata oluştu. Lütfen tekrar deneyin.', 'error'); // Hata mesajı
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md"
    >
      <h2 className="text-2xl font-bold text-center text-gray-700 mb-4">
        İletişim Formu
      </h2>
      <div className="mb-4">
        <label className="block text-gray-600 font-medium mb-2" htmlFor="name">
          İsim
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-600 font-medium mb-2" htmlFor="email">
          E-posta
        </label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-600 font-medium mb-2"
          htmlFor="message"
        >
          Mesajınız
        </label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          className="w-full border border-gray-300 rounded-lg px-4 py-2 h-32 focus:outline-none focus:ring-2 focus:ring-blue-500"
          required
        />
      </div>
      <button
        type="submit"
        className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Gönder
      </button>
    </form>
  );
};

export default ContactForm;
